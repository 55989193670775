import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import persistState from 'redux-localstorage';
import { createEpicMiddleware } from 'redux-observable';
import createHistory from 'history/createBrowserHistory';

import { rootEpic, rootReducer } from './reducers';
import { tasksUrlEnhancer } from './reducers/LocationReducers.js';
import { loadScript } from './util/dom.js';

import INITIAL_STATE from './constants/InitialState.js';

const epicMiddleware = createEpicMiddleware({
  dependencies: { loadScript }
});

export const history = createHistory();

const localStorageSlicer = () => state => ({
  tasks: { ...INITIAL_STATE.tasks, history: { ...state.tasks.history } }
});

const localStorageEnhancer = persistState('tasks', {
  key: 'fst-insights',
  slicer: localStorageSlicer
});

const enhancers = [localStorageEnhancer, tasksUrlEnhancer(history)];
const middleware = [epicMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(
  rootReducer(history),
  INITIAL_STATE,
  composedEnhancers
);

epicMiddleware.run(rootEpic);

export default store;
