import { chunk, flow, map } from 'lodash/fp';
import queryString from 'query-string';

export const serializeValues = state => {
  const result = [];
  for (let k in state) {
    let val = state[k];
    if (val && typeof val === 'object') {
      result.push(serializeValues(val));
    } else {
      if (typeof val === 'number') {
        val = parseFloat(val.toFixed(2));
      }
      if (val === 'http/1.1') {
        val = 'h1';
      }
      result.push(val);
    }
  }
  return result;
};

const valueToProtocol = val =>
  ({
    h1: 'http/1.1'
  }[val]);

const valuesToResultModel = values => ({
  id: values[0],
  raw: {
    id: values[1],
    attempted_id: values[2],
    next_hop_protocol: valueToProtocol(values[3]),
    worker_start: parseFloat(values[4]),
    redirect_start: parseFloat(values[5]),
    redirect_end: parseFloat(values[6]),
    fetch_start: parseFloat(values[7]),
    domain_lookup_start: parseFloat(values[8]),
    domain_lookup_end: parseFloat(values[9]),
    connect_start: parseFloat(values[10]),
    connect_end: parseFloat(values[11]),
    secure_connection_start: parseFloat(values[12]),
    request_start: parseFloat(values[13]),
    response_start: parseFloat(values[14]),
    response_end: parseFloat(values[15]),
    transfer_size: parseFloat(values[16]),
    encoded_body_size: parseFloat(values[17]),
    decoded_body_size: parseFloat(values[18]),
    start_time: parseFloat(values[19]),
    duration: parseFloat(values[20])
  },
  dns: parseFloat(values[21]),
  connection: parseFloat(values[22]),
  tls: parseFloat(values[23]),
  ttfb: parseFloat(values[24]),
  download: parseFloat(values[25]),
  total: parseFloat(values[26]),
  totalTime: parseFloat(values[27])
});

const valuesToMetaDataModel = values => ({
  test_id: values[0],
  test_server: {
    datacenter: values[1]
  },
  test_timestamp: values[2],
  task_type: values[3],
  client_user_agent: values[4],
  client_ip: values[5],
  client_asn: parseFloat(values[6]),
  client_region: values[7],
  client_country_code: values[8],
  client_continent_code: values[9],
  client_metro_code: values[10],
  client_postal_code: values[11],
  client_conn_speed: values[12],
  client_gmt_offset: values[13],
  client_latitude: parseFloat(values[14]),
  client_longitude: parseFloat(values[15]),
  resolver_ip: values[16],
  resolver_asn: parseFloat(values[17]),
  resolver_region: values[18],
  resolver_country_code: values[19],
  resolver_continent_code: values[20],
  resolver_conn_speed: values[21],
  resolver_latitude: parseFloat(values[22]),
  resolver_longitude: parseFloat(values[23])
});

export const deserializeMetaData = query => {
  try {
    const { m: raw } = queryString.parse(query);
    return valuesToMetaDataModel(raw.split(','));
  } catch (e) {
    return {};
  }
};

export const deserializeResults = query => {
  try {
    const { r: raw } = queryString.parse(query);
    return flow(
      chunk(28),
      map(valuesToResultModel)
    )(raw.split(','));
  } catch (e) {
    return [];
  }
};

export const hasResults = query => {
  return deserializeResults(query).length > 1;
};
