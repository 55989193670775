import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { APP_URL, APP_TIMEOUT } from '../../constants/env.js';
import { runTest } from '../../actions/TaskActions.js';
import HeaderComp from '../../components/Header/Header.js';

const Header = ({ hasResults, runTest }) => {
  const testAction = () => (!hasResults ? runTest(APP_URL, APP_TIMEOUT) : null);
  return <HeaderComp testAction={testAction} />;
};

const mapStateToProps = state => ({
  hasResults: state.tasks.results.length > 0
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ runTest }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
