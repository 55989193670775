import React from 'react';

const Analytics = () => {
  return (
    <div>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
ga('create', 'UA-25770359-18', 'auto');
ga('send', 'pageview');`
        }}
      />
      <script async src="https://www.google-analytics.com/analytics.js" />
    </div>
  );
};

export default Analytics;
