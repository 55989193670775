import React from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import AsyncComponentFactory from '../Async/AsyncComponentFactory.js';

import Header from '../Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import Analytics from '../../components/Analytics/Analytics.js';

// Load routes async to reduce initial bundle size.
// Use a dynamic import wrapped in an AsyncComponent for webpack code-splitting.
const AsyncHome = AsyncComponentFactory(() => import('../Home/Home.js'));
const AsyncTest = AsyncComponentFactory(() => import('../Test/Test.js'));
const AsyncHistory = AsyncComponentFactory(() =>
  import('../History/History.js')
);
const AsyncDocs = AsyncComponentFactory(() => import('../Docs/Docs.js'));
const AsyncSignup = AsyncComponentFactory(() => import('../Signup/Signup.js'));

const App = () => {
  return (
    <div>
      <Helmet
        defaultTitle="Fastly Insights"
        titleTemplate="Fastly Insights | %s"
      >
        <meta
          name="description"
          content="Real user monitoring of network and performance characteristics to help make the internet faster."
        />
      </Helmet>
      <Header />
      <main className="content">
        <Route exact path="/" component={AsyncHome} />
        <Route exact path="/test" component={AsyncTest} />
        <Route exact path="/history" component={AsyncHistory} />
        <Route exact path="/docs" component={AsyncDocs} />
        <Route exact path="/signup" component={AsyncSignup} />
      </main>
      <Footer />
      <Analytics />
    </div>
  );
};

export default App;
