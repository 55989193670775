import React from 'react';

import ExternalLink from '../ExternalLink/ExternalLink.js';

const Footer = () => {
  const year = new Date().getUTCFullYear();
  return (
    <footer className="footer container margin-top--xl">
      <div className="flex-row">
        <div>
          <p>
            <ExternalLink href="https://github.com/fastly/insights.js/issues">
              Report issues
            </ExternalLink>
          </p>
          <p>
            <ExternalLink href="https://github.com/fastly/insights.js/blob/master/CHANGELOG.md">
              Release notes
            </ExternalLink>
          </p>
          <p>
            <ExternalLink href="https://www.fastlylabs.com/terms">
              Terms of use
            </ExternalLink>
          </p>
          <p>
            <ExternalLink href="https://www.fastly.com/privacy">
              Privacy
            </ExternalLink>
          </p>
          <p>
            <ExternalLink href="https://www.fastly.com/cookies">
              Cookies
            </ExternalLink>
          </p>
        </div>

        <div className="flex-col--pushed">
          <p>Copyright © {year} Fastly Inc. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
