import {
  Observable,
  from as ObservableFrom,
  of as ObservableOf,
  race
} from 'rxjs';
import {
  bufferCount,
  defaultIfEmpty,
  delay,
  filter,
  map,
  mergeMap,
  withLatestFrom
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
  push as pushUrlState,
  replace as replaceUrlState
} from 'connected-react-router';
import { removeChildFromBody } from '../util/dom.js';
import {
  RUN_TEST,
  LOAD_HISTORY,
  LOADED,
  ADD_TASK_RESULT,
  ERROR
} from '../constants/ActionTypes.js';
import { mapStateToLocation } from '../reducers/LocationReducers.js';

export function observeResults$() {
  return Observable.create(function subscribe(observer) {
    const handler = {
      set(obj, prop, val) {
        if (prop !== 'length') {
          observer.next(val);
        }
        return Reflect.set(obj, prop, val);
      }
    };

    window.FASTLY = {};
    window.FASTLY.results = new Proxy([], handler);
  });
}

export function getInsightScripts$() {
  return ObservableFrom(
    [...document.body.getElementsByTagName('script')].filter(s =>
      /fastly-insights/.test(s.src)
    )
  );
}

const isPopResult = ({ task_type }) => task_type === 'pop';

// ACTION CREATORS
export const runTest = (url, timeout) => ({
  type: RUN_TEST,
  url,
  timeout
});

export const loaded = () => ({
  type: LOADED
});

export const addTaskResult = task => ({
  type: ADD_TASK_RESULT,
  task
});

export const error = message => ({
  type: ERROR,
  message
});

export const loadHistory = tasks => ({
  type: LOAD_HISTORY,
  tasks
});

// ACTION EPICS
export const runTestEpic = (action$, state$, { loadScript }) =>
  action$.pipe(
    ofType(RUN_TEST),
    mergeMap(({ url }) =>
      getInsightScripts$().pipe(
        map(removeChildFromBody),
        bufferCount(2),
        defaultIfEmpty('no scripts'),
        map(() => ObservableOf(loadScript(url))),
        map(loaded)
      )
    )
  );

export const resetUrlEpic = action$ =>
  action$.pipe(
    ofType(RUN_TEST),
    mergeMap(() => ObservableOf('/test').pipe(map(pushUrlState)))
  );

export const watchTasksEpic = action$ =>
  action$.pipe(
    ofType(RUN_TEST),
    mergeMap(({ timeout }) =>
      race(
        observeResults$().pipe(filter(isPopResult), map(addTaskResult)),
        ObservableOf(error('We timed out fetching tasks')).pipe(delay(timeout))
      )
    )
  );

export const loadTaskHistoryEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_HISTORY),
    mergeMap(action => ObservableOf(...action.tasks).pipe(map(addTaskResult)))
  );

export const updateUrlEpic = (action$, state$) =>
  action$.pipe(
    ofType(ADD_TASK_RESULT),
    withLatestFrom(state$),
    mergeMap(([, state]) =>
      ObservableOf(mapStateToLocation(state)).pipe(map(replaceUrlState))
    )
  );
