import {
  flow,
  first,
  getOr,
  last,
  keys,
  mapKeys,
  mapValues,
  omit,
  replace,
  sortBy,
  union
} from 'lodash/fp';

import { HISTORY_MAX_ITEMS } from '../constants/env.js';

export function getTimings({
  domain_lookup_start,
  domain_lookup_end,
  connect_start,
  connect_end,
  secure_connection_start,
  secure_connection_end,
  request_start,
  response_start,
  response_end
}) {
  return mapValues(parseInt)({
    dns: domain_lookup_end - domain_lookup_start,
    connection: secure_connection_start - connect_start,
    tls: connect_end - secure_connection_start,
    ttfb: response_start - request_start,
    download: response_end - response_start,
    total: response_end - domain_lookup_start
  });
}

export function parseTask(task) {
  const raw = flow(
    JSON.parse,
    mapKeys(replace('subject_', ''))
  )(task.task_client_data);
  const timing = getTimings(raw);
  const { id } = raw;
  return { id, raw, ...timing };
}

export function getLongestTime(results) {
  return flow(
    sortBy(['total']),
    last,
    getOr(0, 'total')
  )(results);
}

export function decorateTasks(tasks) {
  const totalTime = getLongestTime(tasks);
  return tasks.map(task => ({ ...task, totalTime }));
}

export function parseMetaData(task) {
  return omit([
    'test_api_key',
    'test_lib_version',
    'task_schema_version',
    'task_client_data',
    'task_server_data'
  ])({
    ...task,
    test_server: JSON.parse(task.test_server)
  });
}

export function updateTaskHistory(task, history) {
  const id = task.test_id;
  let result = { ...history };

  if (keys(result).length > HISTORY_MAX_ITEMS) {
    result = flow(
      keys,
      sortBy(['test_timestamp']),
      first,
      omit
    )(result)(result);
  }

  result[id] = flow(
    getOr([], id),
    union([task])
  )(result);

  return result;
}
