import React from 'react';

const ExternalLink = ({ href, children }) => (
  <a
    target="_blank"
    className="external-link"
    href={href}
    rel="noopener noreferrer"
  >
    {children}
  </a>
);

export default ExternalLink;
