import {
  hasResults,
  serializeValues,
  deserializeResults,
  deserializeMetaData
} from '../model/url.js';

export function mapStateToLocation(state) {
  const { location } = state.router;
  let path = location.pathname;
  switch (path) {
    case '/test':
      const results = serializeValues(state.tasks.results);
      const metaData = serializeValues(state.tasks.metaData);
      return `${path}?m=${metaData}&r=${results}`;
    default:
      return path;
  }
}

export function tasksUrlReducer(state, location) {
  const { pathname, search } = location;
  switch (pathname) {
    case '/test':
      if (search === '' || !hasResults(search)) return state;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          isLoading: false,
          metaData: deserializeMetaData(search),
          results: deserializeResults(search)
        }
      };
    default:
      return state;
  }
}

export function tasksUrlEnhancer(history) {
  return next => (reducer, initialState, enhancer) => {
    if (typeof initialState === 'function' && typeof enhancer === 'undefined') {
      enhancer = initialState;
      initialState = undefined;
    }

    const state = tasksUrlReducer(initialState, history.location);
    return next(reducer, state, enhancer);
  };
}
