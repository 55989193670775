import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { APP_URL, APP_TIMEOUT } from './constants/env.js';
import store, { history } from './store.js';
import { runTest } from './actions/TaskActions.js';
import App from './containers/App/App.js';

import './all.css';
import registerServiceWorker from './registerServiceWorker';

const appWithStoreAndRouter = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
);

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(appWithStoreAndRouter, rootElement);
} else {
  render(appWithStoreAndRouter, rootElement);
}

const { isLoading, hasDNT } = store.getState().tasks;
if (
  isLoading &&
  !hasDNT &&
  store.getState().router.location.pathname === '/test'
) {
  store.dispatch(runTest(APP_URL, APP_TIMEOUT));
}

registerServiceWorker();
