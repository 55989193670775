import * as React from 'react';

const FastlyLabs = () => (
  <svg
    className="fastlylabs"
    viewBox="0 0 231 53.8"
    version="1.1"
    width="150"
    height="39"
  >
    <path d="M136.7 40.3c-.8-.7-1.2-1.9-1.2-3.4V13.1h-6.6V9.7h10.5v26.9c0 .5.1.8.4 1 .2.2.6.3 1.2.3h6v3.4h-7.6c-1.1.1-2-.3-2.7-1zM167.7 41c-.5-.2-.9-.6-1.2-1-.7.9-1.6 1.4-2.8 1.4h-4c-2.3 0-4-.5-5-1.4-1-.9-1.6-2.5-1.6-4.7v-.9c0-4.7 2.2-7 6.6-7h5.6v-2.3c0-.9-.3-1.7-.9-2.2-.6-.5-1.4-.8-2.5-.8h-7v-3.4h6.6c2.6 0 4.6.6 5.8 1.7 1.3 1.1 1.9 3 1.9 5.5v10.9c0 .5.2.8.5 1 .3.2.9.3 1.7.3v3.2H169c-.4.1-.9 0-1.3-.3zm-2.7-3.6c.2-.4.4-1 .4-1.6v-5h-5.7c-.8 0-1.4.3-1.9.8s-.8 1.2-.8 1.9v2.3c0 .8.2 1.4.5 1.7.4.4.9.5 1.7.5h4c1 0 1.5-.2 1.8-.6zM193.1 39.7c-1.3 1.1-3.2 1.7-5.8 1.7h-8.9V9.7h3.8v10c.9-.6 2-.9 3.4-.9h1.6c2.7 0 4.6.6 5.8 1.7 1.2 1.1 1.9 3 1.9 5.5v8.1c.1 2.6-.5 4.4-1.8 5.6zM183 23c-.5.5-.7 1.1-.7 1.7V38h5.5c1.1 0 2-.3 2.5-.8.6-.5.9-1.2.9-2.2V25c0-.9-.3-1.7-.9-2.2-.6-.5-1.4-.8-2.5-.8h-2.5c-1 .2-1.8.4-2.3 1zM216.3 40c-1.2.9-2.8 1.4-5 1.4h-6.7V38h7.2c.8 0 1.4-.2 1.9-.5.4-.3.6-.9.6-1.5v-2.4c0-1.4-.6-2-1.9-2h-1.7c-2.1 0-3.7-.5-4.7-1.4-1-.9-1.6-2.4-1.6-4.4v-1.3c0-2 .6-3.5 1.7-4.4 1.1-.9 2.8-1.3 5.1-1.3h6.1v3.4h-6.5c-.8 0-1.4.2-1.8.5s-.7.9-.7 1.5v1.6c0 .7.2 1.2.6 1.5.4.3.9.5 1.6.5h1.5c2 0 3.6.5 4.6 1.5 1.1 1 1.6 2.4 1.6 4.4v2c-.2 1.9-.7 3.4-1.9 4.3z" />
    <path
      d="M231 53.8H118.4V0H231v53.8zm-111.6-1H230V1H119.4v51.8z"
      fill="#d0d2d3"
    />
    <path
      style={{ fill: '#e82c2a' }}
      d="M76.6 12.2v29.3h8.8V37h-2.9V7.7h-5.9zM0 37h3V22.9H0V19l3-.5v-3.9c0-4.8 1-6.9 7.1-6.9 1.3 0 2.9.2 4.2.4l-.8 4.8c-.9-.1-1.4-.2-2-.2-2.1 0-2.7.2-2.7 2.3v3.4h4.5v4.4H8.9V37h3v4.5H0V37zM73.6 35.6c-.9.2-1.7.2-2.3.2-2.4.1-2.2-.7-2.2-3v-9.9h4.6v-4.4h-4.6V7.7h-5.9V34c0 5.2 1.3 7.5 6.8 7.5 1.3 0 3.1-.3 4.5-.6l-.9-5.3zM55.8 22.9v-.8c-1.8-.3-3.6-.3-4.5-.3-2.7 0-3.1 1.5-3.1 2.2 0 1.1.4 1.7 3.4 2.4 4.4 1 8.7 2 8.7 7.4 0 5.1-2.6 7.8-8.2 7.8-3.7 0-7.3-.8-10.1-1.5v-4.4h4.5v.8c1.9.4 3.9.3 5 .3 2.9 0 3.4-1.6 3.4-2.4 0-1.2-.8-1.7-3.6-2.3-5.2-.9-9.3-2.7-9.3-7.9 0-5 3.3-6.9 8.9-6.9 3.8 0 6.6.6 9.4 1.3V23h-4.5zM28.6 26.7l-.4-.4-2.3 2c-.1 0-.2-.1-.4-.1-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1.6 0 1.1-.5 1.1-1.1 0-.1 0-.2-.1-.3l2.1-2.3z"
    />
    <path
      style={{ fill: '#e82c2a' }}
      d="M37.7 35.6V17.2h-5.9V19c-1.2-.7-2.6-1.3-4-1.5v-2h.7V14h-5.9v1.5h.7v2c-5.7 1-9.9 6-9.9 11.9 0 6.7 5.4 12.1 12.1 12.1 2.3 0 4.4-.6 6.3-1.7l1.1 1.7h6.2v-5.9h-1.4zm-11.8 0v-.7h-.7v.7c-3.2-.2-5.7-2.7-5.9-5.9h.7V29h-.7c.2-3.2 2.7-5.7 5.9-5.9v.7h.7v-.7c3.1.2 5.6 2.6 5.9 5.7v.2H31v.7h.7v.2c-.2 3.1-2.7 5.5-5.8 5.7zM100.4 18.5h12.2v4.4h-2.9l-7.5 18.4c-2.1 5.2-5.7 10-11 10-1.3 0-3.1-.1-4.3-.4l.5-5.4c.8.1 1.8.2 2.3.2 2.5 0 5.3-1.5 6.2-4.2l-7.6-18.6h-2.9v-4.4h12.2v4.4h-2.9L99 33.4l4.3-10.6h-2.9v-4.3z"
    />
  </svg>
);

export default FastlyLabs;
