import INITIAL_STATE from '../constants/InitialState.js';
import {
  RUN_TEST,
  LOAD_HISTORY,
  ADD_TASK_RESULT,
  ERROR
} from '../constants/ActionTypes.js';
import {
  parseTask,
  decorateTasks,
  parseMetaData,
  updateTaskHistory
} from '../model/Task.js';

export default (state = INITIAL_STATE.tasks, action) => {
  switch (action.type) {
    case RUN_TEST:
      return {
        ...state,
        isLoading: true,
        hasErrored: false,
        metaData: {},
        results: []
      };
    case LOAD_HISTORY:
      return {
        ...state,
        isLoading: true,
        hasErrored: false,
        metaData: {},
        results: []
      };
    case ADD_TASK_RESULT:
      return {
        ...state,
        isLoading: false,
        hasErrored: false,
        metaData: parseMetaData(action.task),
        results: decorateTasks([...state.results, parseTask(action.task)]),
        history: updateTaskHistory(action.task, state.history)
      };
    case ERROR:
      return {
        ...state,
        isLoading: false,
        hasErrored: true,
        errorMsg: action.message
      };
    default:
      return { ...state };
  }
};
