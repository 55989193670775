export const createScriptElement = src => {
  const s = document.createElement('script');
  s.defer = true;
  s.src = src;
  return s;
};
export const appendChildToBody = el => document.body.appendChild(el);
export const removeChildFromBody = el => document.body.removeChild(el);
export const loadScript = url => appendChildToBody(createScriptElement(url));

export const dntValueToBool = value => ['yes', '1'].includes(value);

export const getDoNotTrack = () => {
  const hasWindowDNT = 'doNotTrack' in window;
  const hasNavigatorDNT = 'doNotTrack' in navigator;

  let val;

  if (hasNavigatorDNT) {
    val = navigator.doNotTrack;
  } else if (hasWindowDNT) {
    val = window.doNotTrack;
  } else {
    val = '0';
  }

  return dntValueToBool(val);
};
export const doNotTrack = getDoNotTrack();

export const getDevicePixelRatio = () => {
  return ('devicePixelRatio' in window && window.devicePixelRatio) || 1;
};
export const isRetina = getDevicePixelRatio() > 1;

export const getViewportDimensions = () => ({
  width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
  height: Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  )
});
