import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { connectRouter } from 'connected-react-router';
import {
  watchTasksEpic,
  runTestEpic,
  loadTaskHistoryEpic,
  resetUrlEpic
} from '../actions/TaskActions.js';
import tasks from './TaskReducers.js';

export const rootEpic = combineEpics(
  watchTasksEpic,
  runTestEpic,
  loadTaskHistoryEpic,
  resetUrlEpic
);

export const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    tasks
  });
