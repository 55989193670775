import { doNotTrack } from '../util/dom.js';

const INITIAL_STATE = {
  tasks: {
    results: [],
    history: {},
    metaData: {},
    order: 'ASC',
    isLoading: true,
    hasErrored: false,
    errorMsg: '',
    hasDNT: doNotTrack
  }
};

export default INITIAL_STATE;
